.firstScreen {
  position: relative;
  width: 100vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  animation: fadeIn 2s ease-in-out;
  gap: 30px;
  padding-bottom: 40px;

  &_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
    width: 100%;
    margin: 0 auto;
    z-index: 3;
  }

  &_gradient {
    position: relative;
    z-index: 0;
  }

  .whiteGradient {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 5%);
    width: 200%;
    height: 400px;
    background: rgba(255, 255, 255, 0.97);
    filter: blur(20px);
  }

  .bg_image2 {
    background: url("../../img/firstScreen/first_screen.jpg") no-repeat;
    background-position: center top;
    background-size: contain;
    max-width: 500px;
    width: 100%;
    height: 300px;
    max-width: 440px;
    z-index: 0;
    margin: 0 auto;
  }

  .title {
    position: relative;
    width: calc(100vw - 40px);
    max-width: 390px;

    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: normal;
    z-index: 3;
  }

  .marked {
    width: calc(100vw - 40px);
    position: relative;
    color: #9438EF;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    z-index: 3;
  }

  .logo {
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 3;
  }

  .title_description {
    position: relative;
    color: #505050;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    z-index: 3;
  }

  .title_additional {
    position: relative;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 26px;
    z-index: 3;
  }

  .privacy {
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    max-width: 285px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #8B89FE;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }


  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    gap: 24px;

    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    .footer {
      width: 100vw;
      border-radius: 0;
    }
  }

  .link {
    color: var(--Indigo-1, #8B89FE);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }

  ul {
    position: relative;
    list-style: none;
    padding: 0;
    width: 100%;
    margin-top: 10px;
    z-index: 3;

    li {
      padding: 14px 16px;
      width: calc(100vw - 40px);
      max-width: 398px;
      background-color: #FAFAFF;
      border-radius: 16px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      z-index: 3;

      color: #333;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      .fade {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }

      &:hover {
        background-color: #B2B1FF;
      }

      &.checked {
        background-color: #B2B1FF;
      }

      .quest_icon {
        width: 32px;
      }

      .quest_textAndIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      .quest_textWithDescription {
        display: flex;
        flex-direction: column;

        &_bold {
          color: #333;
          font-family: Gilroy;
          font-size: 17px;
          font-style: normal;
          letter-spacing: normal;
          font-weight: 900;
          line-height: normal;
        }

        &_description {
          color: #333;
          font-family: Gilroy;
          font-size: 15px;
          letter-spacing: normal;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  .radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    min-width: 8px;
    max-width: 8px;
    height: 15px;
    min-height: 15px;
    max-height: 15px;
  }

  li:hover .radio svg path {
    fill: #fff;
  }

  li.checked .radio svg path {
    fill: #fff;
  }
}

@media (max-height: 650px) {
  .firstScreen_container {
    height: 90vh;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}