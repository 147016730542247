.preLoad {
  border-radius: 30px;
  padding: 40px 20px 20px;
  width: 100vw;
  height: 100vh;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  transition: opacity 1s ease-in-out;

  &.hide {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .loadTitle {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100vw - 40px);
    max-width: 398px;
    transform: translate(-50%, -1000%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 8px;
    color: #8B89FE;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 42px;
    margin-top: 42px;
  }

  .spinner {
    width: 26px;
    animation: spin 2s linear infinite;
  }

  .slide {
    width: 100%;
  }

  .loader_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .progressContainer {
    position: relative;
    width: calc(100vw - 40px);
    max-width: 398px;
    margin: 0 auto;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    margin: 0 auto;
    margin-top: 20px;
    width: 246px;
    color: #333;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(8, 11, 39, 0.34);
    z-index: 3;
  }

  .modal {
    position: fixed;
    width: 100%;
    max-width: 358px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 32px;
    text-align: center;
    border-radius: 30px;
    background: #FCFCFC;
    z-index: 4;
  }

  .modal_question {
    color: #333;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 26px;
  }

  .modal_description {
    margin: 8px auto 32px auto;
    color: #424242;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    max-width: 294px;
  }

  .modal_buttons {
    display: flex;
    justify-content: space-between;
  }

  .modal_button {
    flex: 1;
    padding: 12px;
    margin: 0 5px;
    cursor: pointer;
    background-color: #4cc790;
    border: none;
    color: #FCFCFC;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 40px;
    background: #8B89FE;

    &:active {
      box-shadow: none;
    }
  }
}

.testimonials_container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 130px;
}

.testimonials_swiper {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  margin: 0 auto;
  padding: 0 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}