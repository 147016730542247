.resultPage {
  padding: 0px 0 0;
  animation: fadeIn 2s ease-in-out;
  background-color: #FAFAFF;
  padding-bottom: 60px;

  .btn {
    display: block;
    border-radius: 60px;
    background: #8B89FE;
    padding: 18px;
    width: calc(100vw - 40px);
    max-width: 398px;
    margin: 0 auto;
    margin-top: 40px;

    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}