.paywall {
  position: relative;
  border-radius: 30px;
  padding: 20px 20px 0px 20px;
  width: 100vw;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  animation: fadeIn 2s ease-in-out;

  .btn {
    display: block;
    border-radius: 60px;
    background: #8B89FE;
    padding: 18px;
    width: calc(100vw - 40px);
    max-width: 398px;
    margin: 0 auto;
    margin-top: 40px;

    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    color: #333;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;

    &_logo {
      margin-top: 12px;
      width: 65px;
      height: 65px;
    }
  }

  .bg_gray {
    background-color: #FAFAFF;
    padding: 22px;
    margin-bottom: 32px;
  }

  .chartTitle {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 14px;

    &_title {
      color: #333;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left;
    }

    &_stats {
      color: #333;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;

    }

    &_weight {
      color: #7270FF;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}