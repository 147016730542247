.thanksPage {
  border-radius: 30px;
  padding: 40px 20px 20px;
  width: 100vw;
  height: 100vh;
  max-height: 700px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  animation: fadeIn 2s ease-in-out;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .navbar {
    width: 100vw;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    gap: 18px;

    h2 a {
      color: #333;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .title {
    width: 100%;
    max-width: 390px;
    margin: 24px auto 8px auto;

    color: #333;
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.48px;
    text-transform: capitalize;
  }

  .logo {
    width: 62px;
    height: 62px;
    filter: drop-shadow(5px 5px 20px rgba(192, 193, 193, 0.30));
  }

  .title_description {
    color: #333;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 0 20px;
    margin-bottom: 36px;

    &_already_paid {
      padding: 0 20px;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.28px;
      margin-bottom: 36px;
    }
  }

  .tnx_button {
    display: block;
    border-radius: 60px;
    border: 0;
    padding: 15px 30px;
    background-color: #8C8AFF;
    color: #FAFAFA;
    width: 100%;
    height: 60px;
    max-width: 285px;
    cursor: pointer;
    margin: 0 auto;

    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}