.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  min-width: 300px;
  max-width: 400px;
  transform: translate(-50%, -50%);
  background: white;
  padding: 80px 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.card_element {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  height: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.card_element:focus {
  border-color: #4caf50;
}

.payButton {
  width: 100%;
  padding: 10px;
  background-color: #8C8AFF;
  color: #FAFAFA;
  border: none;
  font-size: 20px;
  font-weight: 600;
  border-radius: 16px;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

.payButton:disabled {
  background-color: #dddddd;
  cursor: not-allowed;
}

.payButton:hover {
  background-color: #45a049;
}

.footer_description {
  opacity: 0.4;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.24px;
  width: 280px;
  margin: 16px auto 20px auto;
}

.close {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 150px;
  border-radius: 16px;
  margin: 20px 0;
}