.Cards-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.Cards-swiper {
  width: 100%;
  margin: 0 auto;
}

.card-swiper-slide-content {
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
}

.card-swiper-container {
  overflow: visible !important;
}

.swiper-slide {
  min-width: 190px;
}