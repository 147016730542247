.container {
  height: 100vh;
  background: #FAFAFF;
}

.login {
  position: relative;
  overflow: scroll;
  padding: 20px;
  width: 100vw;
  max-width: 500px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  animation: fadeIn 2s ease-in-out;
  max-width: 100%;

  .description {
    width: calc(100vw - 40px);
    max-width: 398px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 32px;
  }

  .privacy {
    // position: fixed;
    // bottom: 0;
    // left: 50%;
    // transform: translate(-50%, -50%);
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 31px;
    min-width: 250px;
    max-width: 320px;

    color: #9A98FF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    .link {
      font-weight: 700;
    }

    img {
      width: 18px;
      margin-right: 4px;
      vertical-align: sub;
    }
  }

  .login_button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 18px;
    border-radius: 45px;
    background: #fff;
    width: 100%;
    max-width: 397px;
    cursor: pointer;
    margin: 8px auto;
    transition: box-shadow 0.3s ease-in-out;

    color: #333;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;

    appearance: none;
    -webkit-appearance: none;

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      box-shadow: 5px 5px 10px 0px rgba(0, 36, 89, 0.10);
      -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 36, 89, 0.10);
    }

    &:active {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.GoogleLoginButton {
  cursor: pointer;
  position: absolute;
  opacity: 0.0001;
  top: 2px;
  left: 60px;
  border-radius: 16px;
  background: #222;
  transform: scaleX(1.6) scaleY(1.2);
}