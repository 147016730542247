.quiz {
  border-radius: 30px;
  padding: 20px;
  width: 100vw;
  max-width: 398px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  overflow: scroll;
  padding-bottom: 100px;

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // height: calc(100vh - 140px);
  }

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
    opacity: 0;
    margin-top: 32px;

    li {
      padding: 17px 26px 18px 26px;
      max-width: 398px;
      background-color: #FAFAFF;
      border-radius: 16px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      color: #333;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      .fade {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }

      &:hover {
        background-color: #B2B1FF;
      }

      &.checked {
        background-color: #B2B1FF;
      }

      .quest_icon {
        width: 32px;
      }

      .quest_textAndIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      .quest_textWithDescription {
        display: flex;
        flex-direction: column;

        &_bold {
          color: #333;
          font-family: Gilroy;
          font-size: 17px;
          font-style: normal;
          letter-spacing: normal;
          font-weight: 900;
          line-height: normal;
        }

        &_description {
          color: #333;
          font-family: Gilroy;
          font-size: 15px;
          letter-spacing: normal;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }

  .radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    min-width: 8px;
    max-width: 8px;
    height: 15px;
    min-height: 15px;
    max-height: 15px;

    &_marked {
      width: 20px;
      min-width: 20px;
      max-width: 20px;
    }
  }

  li:hover .radio svg path {
    fill: #fff;
  }

  li.checked .radio svg path {
    fill: #fff;
  }

  .next_button_bg {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100vw;
    max-width: 398px;
    height: 150px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.70) 30%, #FFF 70%, #FFF 100%);
    // background: #000;
  }

  .next_button {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: calc(100% - 40px);
    max-width: 398px;
    height: 60px;
    border-radius: 60px;
    background: #8C8AFF;
    color: #FAFAFA;

    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
    border: none;
    outline: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      cursor: auto;
      background: #C6C5FF;
    }
  }

  .withImage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &_img {
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      margin: 0 auto 32px auto;
      max-width: 398px;
    }

    &_text {
      margin: 0px auto 16px 0;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.36px;
    }
  }

  .show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

    .fade {
      visibility: visible;
    }

    .withImage_text {
      opacity: 1;
    }
  }
}

.quiz img {
  width: 100%;
  max-width: 300px;
}

.img_container {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  opacity: 0;

  li {
    width: 165.5px;
    height: 138px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      max-width: 60px;
    }
  }
}

.description {
  width: 100%;
  text-align: left;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 500;
  line-height: 22px;
}

.preventClick {
  pointer-events: none;
}

.privacy {
  margin: 0 auto;
  margin-bottom: 40px;
  min-width: 250px;
  max-width: 320px;
  background: #FAFAFF;
  border-radius: 12px;
  padding: 16px;

  color: #9A98FF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  .link {
    font-weight: 700;
  }

  img {
    width: 24px;
    margin-right: 4px;
  }
}