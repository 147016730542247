.beforeAfterV2 {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 0 auto;
  padding-bottom: 40px;
  margin-top: 10px;

  color: #002459;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  &_description {
    color: #8F8F8F;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  .arrows {
    width: 15px;
    height: 18px;
    margin: 10px;
    margin-top: 50%;
  }

  .mark {
    width: 17px;
    margin-right: 10px;
    vertical-align: sub;
  }
}

.bold {
  font-weight: 600;
}

.block_row {
  position: relative;
  width: 100%;
  max-width: 398px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}

.block_column {
  position: relative;
  width: 100%;
  max-width: 398px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
}

.block_text {
  max-width: 354px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.block {
  position: relative;
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;

  &_img {
    width: 100%;
  }

  &_label {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 4px 28px;
    border-radius: 5.559px;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(5.5px);

    color: #6563FF;
    text-align: center;
    font-size: 19.673px;
    font-weight: 600;
    line-height: 33.97px;
  }

  &_labelStats {
    border-radius: 10px;
    background: var(--Indigo-5, #F4F4FF);
    padding: 16px;
    width: 100%;

    color: #505050;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    &_after {
      background: #E8F9D7;
      color: #509B04;
      font-weight: 600;
    }

    &_fat {
      font-weight: 500;
      text-align: left;

      &_after {
        background: #E8F9D7;
        font-weight: 500;
        text-align: left;
        color: #509B04;

        &_arrow {
          display: flex;
          align-items: center;
          margin-top: 1px;
        }
      }
    }
  }
}

.block.after {
  border: 3px solid #fff;
  filter: drop-shadow(0px 3px 14px rgba(101, 99, 255, 0.70));
}