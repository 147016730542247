.title {
  position: relative;
  width: 100%;
  max-width: 398px;
  margin: 0 auto;
  z-index: 9;

  color: #333;
  font-family: Gilroy;
  font-size: 19px;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 900;
  line-height: 26px;
}

.text_marked {
  color: #34C759;
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
}