.chart {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: calc(100vw - 40px);
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 398px;
	padding: 16px;
	border-radius: 12px;
	border-radius: 12px;
	background: #FFF;
	box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);

	color: #434343;

	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;

	&_text {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	&_title {
		color: #8C8AFF;
		font-size: 18px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
	}
}

.chartGraph {
	display: flex;
	align-items: center;
	flex-direction: column;

	&_container {
		position: relative;
	}

	&_currentWeight {
		position: absolute;
		top: 3%;
		left: 3%;
		color: #333;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 14px;
		text-transform: uppercase;

		&_gain {
			position: absolute;
			top: 70%;
			left: 3%;
		}

		&_save {
			position: absolute;
			top: 30%;
			left: 3%;
		}
	}

	&_goalWeight {
		position: absolute;
		bottom: 20%;
		right: 1%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		opacity: 0.8;
		background: linear-gradient(215deg, #A4A3FF -1.1%, #4441FF 101.1%);
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		padding: 1px 8px;
		width: max-content;

		color: #FFF;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 22.808px;

		&_gain {
			position: absolute;
			bottom: 86%;
			right: 1%;
		}

		&_save {
			position: absolute;
			bottom: 60%;
			right: 1%;
		}
	}

	&_img {
		width: 100%;
	}

	&_date {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 4px;
		color: #C6C5FF;
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
}