.fastingPlan {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: calc(100vw - 40px);
	height: 77px;
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 398px;
	padding: 16px;
	border-radius: 12px;
	border-radius: 12px;
	background: #FFF;
	box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);

	color: #434343;

	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;

	&_text {
		display: flex;
		flex-direction: column;
	}

	&_title {
		color: #8C8AFF;
		font-size: 18px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
	}

	&_icon {
		display: block;
		width: 45px;
	}
}

.fastingDouble {
	display: flex;
	gap: 16px;
	width: calc(100vw - 40px);
	max-width: 398px;
	margin: 0 auto;

	.fastingPlan {
		width: 50%;
		height: 118px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin-bottom: 12px;
	}

	.timeUnit {
		text-transform: uppercase;
	}
}