.Cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 14px;

  color: #002459;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.48px;
}

.card {
  width: 187px;
}

.mainTitle {
  color: #333;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 22px;
  margin-top: 32px;

  .marked {
    color: #8B89FE;
  }
}

.top {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stars_block {
  display: flex;
  flex-direction: column;
}

.stars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star {
  width: 14px;
  height: 14px;
  margin: 4px 0 10px 0;
}

.description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.author {
  color: #B5B5B5;
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}