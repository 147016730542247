.moneyBack {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 14px;
  padding: 0px 16px;

  color: #002459;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.description {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: calc(100vw - 40px);
  max-width: 387px;
}

.bold {
  font-weight: 600;
}

.block_row {
  width: calc(100vw - 40px);
  max-width: 387px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;
}

.block {
  width: calc(100vw - 40px);
  max-width: 387px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  text-align: left;

  color: #333;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &_img {
    width: 100%;
    max-width: 32px;
    max-height: 32px;
    margin-right: 8px;
  }
}

.footer {
  border-top: 1px solid #CACACA;
  margin-top: 44px;
  width: 100vw;
  max-width: 500px;
  padding: 22px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;

  a {
    color: #8B89FE;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.408px;
  }
}