.beforeAfter {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 0 auto;
  padding: 0 16px;
  padding-bottom: 40px;
  padding-top: 60px;

  color: #002459;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  &_title {
    color: #424242;
    font-size: 24px;
    text-align: left;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 20px;

    &_add {
      color: #424242;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  &_description {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    z-index: 3;
  }

  .arrows {
    width: 15px;
    height: 18px;
    margin: 10px;
    margin-top: 32%;
  }

  .mark {
    width: 17px;
    margin-right: 10px;
    vertical-align: sub;
  }

  &_footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 68px;
    width: 120vw;
    max-width: 500px;
    border-radius: 50%;
    background: #FFF;
    box-shadow: 0px 0px 27.564px 0px rgba(115, 114, 169, 0.15), 0px 1.103px 16.538px 0px rgba(115, 114, 169, 0.10);
    z-index: 1;

    &_cut {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -130%);
      width: 100vw;
      height: 40px;
      background: #FFF;
      z-index: 2;
    }

    &_bg {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100vw;
      height: 40px;
      background: #FAFAFF;
      z-index: 0;
    }
  }
}

.bold {
  font-weight: 600;
}

.block_row {
  position: relative;
  width: 100%;
  max-width: 398px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 32px;
}

.block_text {
  max-width: 354px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 16px;
}

.block {
  position: relative;
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  border: 3px solid #fff;

  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;

  &_img {
    width: 100%;
  }

  &_label {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 4px 28px;
    border-radius: 5.559px;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(5.5px);

    color: #6563FF;
    text-align: center;
    font-size: 22.235px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.128px;
  }
}

.block.after {
  filter: drop-shadow(0px 4.447px 22.235px rgba(101, 99, 255, 0.70));
}