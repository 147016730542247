.bg_gray {
  background: #FAFAFF;
  width: inherit;
  margin: 22px 0 32px 0;
}

.markedList {
  width: calc(100vw - 40px);
  max-width: 398px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 16px;
  padding-top: 12px;
  padding-bottom: 12px;

  color: #002459;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  &_title {
    color: #333;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;

    &_add {
      color: #424242;
      font-size: 18px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  &_description {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    z-index: 3;
  }

  .arrows {
    width: 15px;
    height: 18px;
    margin: 10px;
    margin-top: 32%;
  }

  .mark {
    width: 17px;
    margin-right: 10px;
    vertical-align: sub;
  }
}

.bold {
  font-weight: 600;
}

.block_text {
  max-width: 354px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}