.testimonials-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 130px;
}

.testimonials-swiper {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 325px;
  margin: 0 auto;
  padding: 0 20px;
}

.swiper-slide-content {
  padding: 8px 12px;
  text-align: left;
  background-color: #fff;
  box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);
  border-radius: 8px;
}

.swiper-container {
  overflow: visible !important;
}