@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
    url('./fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
    url('./fonts/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
    url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Light'), local('Gilroy-Light'),
    url('./fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
    url('./fonts/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Thin'), local('Gilroy-Thin'),
    url('./fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Black'), local('Gilroy-Black'),
    url('./fonts/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  height: 100vh;
  color: #002459;
  font-family: 'Gilroy', sans-serif;
  letter-spacing: normal;
}

button {
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

section {
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.App {
  position: relative;
  overflow: hidden;

  h1 {
    margin: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select:focus,
  textarea:focus,
  input:focus {
    font-size: 22px;
  }
}