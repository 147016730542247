.timeLastMeal {
	border-radius: 30px;
	padding: 0 20px 20px;
	max-width: 398px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0 auto;

	color: #000;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;

	input {
		outline: none;
		border: none;
		color: #8B89FE;
		text-align: right;
		font-size: 22px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;
		margin-right: 6px;
		width: 70px;
	}

	.weightInput {
		outline: none;
		border: none;
		border-bottom: 1px solid #D8D8F1;
	}

	.units {
		display: flex;
		border-radius: 20px;
		background: #D1D0FF;
		overflow: hidden;
		margin-top: 20px;

		color: #7270FF;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;

		.unit {
			padding: 9px 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #D1D0FF;
			transition: all 0.3s ease-in-out;

			&:hover {
				cursor: pointer;
			}

			&_disabled {
				color: #D1D0FF;
				background: #FFF;
			}
		}
	}
}