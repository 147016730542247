.prices {
  position: relative;
  width: 100vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .title_container {
    position: relative;
    width: 270px;
    text-align: center;
    z-index: 9;
  }

  ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin-top: 20px;
    width: calc(100vw - 40px);
    max-width: 398px;
    z-index: 9;

    li {
      position: relative;
      padding: 15px;
      min-height: 80px;
      border-radius: 10px;
      border: 2px solid #EFEFFF;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &:hover {
        border: 2px solid #8B89FE;
      }
    }
  }

  .selected {
    opacity: 1;
    border: 2px solid #8B89FE;
  }

  .main_part {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .top_part {
    position: absolute;
    top: -10px;
    right: 20px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    padding: 0 10px;
    border-radius: 50px;
    background: #9A98FF;

    &_text {
      color: #FFF;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
    }

    &_heart {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .bottom_part {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left_part {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .price {
    color: #505050;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 4px;
  }

  .discount {
    color: #505050;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: line-through;
    text-transform: capitalize;
  }

  .period {
    color: #505050;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    &_just {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .perPrice {
    color: #505050;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .perText {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    border: 2px solid #D1D0FF;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
  }

  input[type="radio"]::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: transparent;
  }

  input[type="radio"]:checked {
    border: 2px solid #8B89FE;
  }

  input[type="radio"]:checked::before {
    background-color: #8B89FE;
    border: 2px solid #8B89FE;
  }

  .period_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .per_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .termsDescription {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 22px;
    width: calc(100vw - 40px);
    max-width: 398px;

    a {
      color: #8B89FE;
    }
  }
}

.tips {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 285px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.28px;
  text-transform: capitalize;

  &_block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_heart {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }

  &_text {
    opacity: 0.7;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
}

.buyButton {
  display: block;
  border-radius: 60px;
  background: #8B89FE;
  padding: 18px;
  width: calc(100vw - 40px);
  max-width: 398px;
  margin: 0 auto;
  margin-top: 22px;

  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;
}

.card {
  &_icons {
    width: calc(100vw - 40px);
    max-width: 398px;
    margin-top: 22px;
    padding: 0px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 11px;
  }

  &_icon {
    width: 50px;
  }
}