.mainGoal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(100vw - 40px);
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 398px;
	padding: 16px;
	border-radius: 12px;
	border: 2px solid #8B89FE;
	background: #F4F4FF;
	box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);

	color: #434343;

	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px;

	&_text {
		display: flex;
		flex-direction: column;
	}

	&_title {
		color: #8C8AFF;
		font-size: 18px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
	}

	&_icon {
		display: block;
		width: 45px;
	}
}

.reachGoal {
	position: relative;
	border-radius: 12px;
	background: #FFF;
	box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(100vw - 40px);
	margin: 0 auto;
	max-width: 398px;
	padding: 16px;
	margin-bottom: 20px;

	&_text {
		display: flex;
		flex-direction: column;
	}

	&_title {
		color: #8C8AFF;
		font-size: 18px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
		margin-bottom: 37px;
	}

	&_subtitle {
		color: #333;
		font-size: 24px;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
		margin-bottom: 12px;
	}

	&_description {
		color: #333;
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	&_icon {
		position: absolute;
		top: 20%;
		right: 18px;
		display: block;
		width: 187px;
	}
}

.addGoal {
	border-radius: 12px;
	background: #FFF;
	box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: calc(100vw - 40px);
	margin: 0 auto;
	max-width: 398px;
	padding: 16px;
	margin-bottom: 20px;

	&_option {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 8px;
		background: #F4F4FF;
		padding: 6px 12px;

		color: #333;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&_text {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		background: #F4F4FF;
		gap: 12px;
	}

	&_title {
		color: #8C8AFF;
		font-size: 18px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
		margin-bottom: 20px;
	}

	&_icon {
		width: 32px;
	}

	&_mark {
		width: 17px;
	}
}

.dietBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(100vw - 40px);
	margin: 0 auto;
	margin-bottom: 20px;
	max-width: 398px;
	padding: 16px;
	border-radius: 12px;
	border-radius: 12px;
	background: #FFF;
	box-shadow: 0px 0px 25px 0px rgba(115, 114, 169, 0.15), 0px 1px 15px 0px rgba(115, 114, 169, 0.10);

	color: #434343;

	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;

	&_text {
		display: flex;
		flex-direction: column;
	}

	&_title {
		color: #8C8AFF;
		font-size: 18px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
	}

	&_icon {
		display: block;
		width: 45px;
	}
}