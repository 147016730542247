.loader {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 16px;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.8);
}

.lds_roller {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds_roller div {
	animation: lds_roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}

.lds_roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #8C8AFF;
	margin: -4px 0 0 -4px;
}

.lds_roller div:nth-child(1) {
	animation-delay: -0.036s;
}

.lds_roller div:nth-child(1):after {
	top: 63px;
	left: 63px;
}

.lds_roller div:nth-child(2) {
	animation-delay: -0.072s;
}

.lds_roller div:nth-child(2):after {
	top: 68px;
	left: 56px;
}

.lds_roller div:nth-child(3) {
	animation-delay: -0.108s;
}

.lds_roller div:nth-child(3):after {
	top: 71px;
	left: 48px;
}

.lds_roller div:nth-child(4) {
	animation-delay: -0.144s;
}

.lds_roller div:nth-child(4):after {
	top: 72px;
	left: 40px;
}

.lds_roller div:nth-child(5) {
	animation-delay: -0.18s;
}

.lds_roller div:nth-child(5):after {
	top: 71px;
	left: 32px;
}

.lds_roller div:nth-child(6) {
	animation-delay: -0.216s;
}

.lds_roller div:nth-child(6):after {
	top: 68px;
	left: 24px;
}

.lds_roller div:nth-child(7) {
	animation-delay: -0.252s;
}

.lds_roller div:nth-child(7):after {
	top: 63px;
	left: 17px;
}

.lds_roller div:nth-child(8) {
	animation-delay: -0.288s;
}

.lds_roller div:nth-child(8):after {
	top: 56px;
	left: 12px;
}

@keyframes lds_roller {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}